<template>
  <b-row v-if="client">
    <b-col cols="12">
      <div v-if="currentUser.roles[0].name === 'super_admin'" class="d-flex justify-content-between align-items-center pt-50 mb-2">
        <div>
          <h2 class="font-weight-bolder m-0 hGoBack">
            <span @click="$router.go(-1)">
              <b-button variant="white" class="btn-icon rounded-circle mr-50 text-danger bg-white" style="padding: 0.5px">
                <feather-icon icon="ArrowLeftCircleIcon" size="35" />
              </b-button>
            </span>
            {{ $t('cliente_file') }}
          </h2>
        </div>
      </div>
      <!-- info -->
      <b-card no-body>
        <b-row
            align-h="between"
            align-v="center"
            class="px-1 pt-2 pb-1"
        >
          <b-col cols="auto">
            <h3 class="m-0">
              {{ $t('Empresa') }}
            </h3>
          </b-col>
          <b-col cols="auto">
            <div class="text-right">
              <b-link :to="{ name: 'editClient', params: { id: client.id } }" class="btn-primary mr-1">
                <span><feather-icon icon="Edit2Icon" />{{ $t('Editar') }}</span>
              </b-link>
            </div>
          </b-col>
        </b-row>
        <hr />
        <b-row
            class="px-1 pt-2 pb-1">
          <b-col>
            <div class="d-flex">
              <h3 class="font-weight-bolder hTitle">
                {{ client.name }}
              </h3>
              <b-badge class="ml-2 d-flex justify-content-center align-items-center position-relative"
                       :variant="statusVariant(client.active)" style="top: -3px">
                <feather-icon :icon="statusVariantIcon(client.active)" size="14" style="margin-right: 5px;" />
                {{ statusVariantText(client.active) }}
              </b-badge>
            </div>
          </b-col>
        </b-row>
        <hr>
        <b-row class="body-info px-1 pb-1">
          <b-col md="10" sm="12">
            <b-row>
              <b-col md="4" sm="12">
                <p><strong>Code:</strong> <small>{{ client.code }}</small></p>
              </b-col>
              <b-col md="4" sm="12">
                <p>
                  <strong>{{ $t('email') }}:</strong> <small>{{ client.email }}</small>
                </p>
              </b-col>
              <b-col md="4" sm="12">
                <p>
                  <strong>{{ $t('Telefono') }}:</strong> <small>{{ client.phone }}</small>
                </p>
              </b-col>
              <b-col md="4" sm="12">
                <p><strong>CIF:</strong> <small>{{ client.cif }}</small></p>
              </b-col>
              <b-col md="4" sm="12">
                <p><strong>{{ $t('Direccion') }}:</strong> <small>{{ client.address }}</small></p>
              </b-col>
              <b-col md="4" sm="12" v-if="client.province_object">
                <p><strong>{{ $t('Provincia') }}:</strong> <small>{{ client.province_object.name }}</small></p>
              </b-col>
              <b-col md="4" sm="12" v-if="client.province">
                <p><strong>{{ $t('Provincia') }}:</strong> <small>{{ client.province }}</small></p>
              </b-col>
              <b-col md="4" sm="12" v-if="client.type">
                <p><strong>{{ $t('Tipo de empresa') }}:</strong> <small>{{ client.type }}</small></p>
              </b-col>
              <b-col md="4" sm="12">
                <p><strong>{{ $t('CodigoPostal') }}:</strong> <small>{{ client.postalCode }}</small></p>
              </b-col>
              <b-col md="4" sm="12" v-if="client.country">
                <p><strong>{{ $t('Pais') }}:</strong> <small>{{ client.country.title[currentLanguage] }}</small></p>
              </b-col>
              <b-col cols="12" v-if="client.description != ''">
                <p><strong>{{ $t('Descripcion') }}:</strong></p>
                <div v-html="client.description"></div>
              </b-col>
            </b-row>
          </b-col>

          <b-col md="2" sm="12">
            <img :src="client.image.path" class="w-75" :alt="client.name" v-if="client.image">
          </b-col>
        </b-row>
      </b-card>

      <!-- tabs -->
      <b-tabs content-class="col-12 mt-1 mt-md-0  px-0" nav-wrapper-class="col-12" nav-class="nav-left">
        <!--  obras -->
        <b-tab active v-if="currentUser.roles[0].name !== 'user'">
          <template #title>
            <feather-icon icon="HomeIcon" size="18" class="mr-50" />
            <span class="font-weight-bold">Obras</span>
          </template>

          <!-- table -->
          <b-card no-body>
            <!-- header -->
            <div class="d-flex justify-content-between align-items-center px-1 py-50">
              <div class="col-3">
                <h3 class="m-0 h3CardTitle">{{ "Obras" }}</h3>
              </div>
              <div class="d-flex align-items-center">
                <div v-if="currentUser.roles[0].name === 'super_admin'">
                  <b-link :to="{ name: 'createProject', params: { client_param: client.id } }">
                    <b-button variant="primary" size="sm" class="mr-1 text-nowrap">
                      {{ "+ Añadir obra" }}
                    </b-button>
                  </b-link>
                </div>
              </div>
            </div>
            <hr class="m-0" />
            <!-- filters -->
            <div class="p-1">
              <div class="col-12">
                <b-row>
                  <b-col class="col-11"></b-col>
                  <b-col class="col-1 text-right">
                    <span class="cursor-pointer" @click="visibleFilter = !visibleFilter">
                      <feather-icon icon="FilterIcon" size="20" />
                    </span>
                  </b-col>
                </b-row>
              </div>
              <b-collapse id="collapse" v-model="visibleFilter" class="mt-2">
                <div class="px-2">
                  <b-row>
                    <b-col sm="6">
                      <b-form-group label-for="filter-status">
                        <v-select v-model="status" label="name" :filterable="false" :searchable="false"
                          :options="selectStatus" :placeholder="'Buscar por Estado'" />
                      </b-form-group>
                    </b-col>

                    <b-col sm="6">
                      <div class="d-flex justify-content-end align-items-center">
                        <b-form-input v-model="searchTerm" :placeholder="$t('Busqueda')" type="text"
                          class="d-inline-block w-auto" />
                        <b-button class="ml-1" @click="handleSearch" variant="primary">
                          {{ $t("Buscar") }}
                        </b-button>
                      </div>
                    </b-col>
                  </b-row>
                </div>
              </b-collapse>
            </div>
            <b-table hover responsive :fields="projectColumns" :items="projects" class="table-white">
              <template #cell(name)="data">
                <b-link :to="{ name: 'viewProject', params: { id: data.item.id } }" class="link">
                  {{ data.item.name }}
                </b-link>
              </template>
              <template #cell(status)="data">
                <b-badge :variant="statusAssetVariants[data.item.status.alias]">
                  <feather-icon :icon="statusVariantIcon(data.item.status.alias)" size="14" style="margin-right: 5px;" />
                  {{
                    data.item.status.name
                  }}
                </b-badge>
                <b-badge v-if="(data.item.inicidence == 1)" variant="light-warning" style="margin-left: 5px;">
                  <feather-icon icon="AlertTriangleIcon" size="22" />
                </b-badge>
              </template>
              <template #cell(actions)="data">
                <span>
                  <b-link :to="{ name: 'viewProject', params: { id: data.item.id } }"
                    class="d-inline-block px-50 text-dark">
                    <feather-icon icon="EyeIcon" />
                  </b-link>
                  <b-link v-if="currentUser.roles[0].name === 'super_admin'"
                    :to="{ name: 'editProject', params: { id: data.item.id } }" class="d-inline-block px-50 text-dark">
                    <feather-icon icon="Edit2Icon" />
                  </b-link>
                  <span v-if="currentUser.roles[0].name === 'super_admin'">
                    <span v-if="data.item.canBeDeleted" class="d-inline-block px-50 text-danger"
                      @click="deleteProject(data.item.id, data.item.name)">
                      <feather-icon icon="TrashIcon" />
                    </span>
                  </span>
                </span>
              </template>
            </b-table>
            <!-- pagination -->
            <div v-if="totalProjects > pageLength" class="card-footer list-footer d-flex align-items-center justify-content-between mb-1 px-2">
              <div class="d-flex align-items-center mb-0">
                <span class="text-nowrap"> {{ "Por página" }}: </span>
                <b-form-select v-model="pageLength" :options="pages" class="ml-50 mr-1" @input="handlePageChange" />
                <span class="text-nowrap"> {{ "Total" }}: {{ totalProjects }}</span>
              </div>
              <div>
                <b-pagination v-model="currentPage" :total-rows="totalProjects" :per-page="pageLength" first-number
                  last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0"
                  @change="handleChangePage">
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>

          </b-card>
        </b-tab>
        <!-- direcciones -->
        <b-tab>
          <template #title>
            <feather-icon icon="MapIcon" size="18" class="mr-50" />
            <span class="font-weight-bold">{{ $t('addresses') }}</span>
          </template>
          <b-card>
            <b-row class="body-info">
              <!-- shipping -->
              <b-col md="6" sm="12" class="py-1">
                <p class="font-weight-bold">
                  {{ $t('shipping_address_facility') }}
                </p>
                <hr>
                <b-row v-for="(item, index) in client.shipping_address" :id="item.id" :key="item.id" ref="row">
                  <b-col sm="12">
                    <b-row>
                      <b-col lg="7" cols="9">
                        <b-form-group v-slot="{ shippingoptions }">
                          <b-form-radio v-model="shipping" :aria-describedby="shippingoptions" name="shipping-radios"
                            :value="item.id">{{ item.name }}</b-form-radio>
                        </b-form-group>
                      </b-col>
                      <b-col lg="5" cols="3" class="px-10 text-right d-flex justify-content-end">
                        <b-link id="show-btn click"
                                class="pr-1"
                                @click="updateAdd(item); type = 'shipping'; $bvModal.show('add-address') ">
                          <span class="pr-0_5">
                            <feather-icon icon="Edit2Icon" />
                          </span>
                          <span class="d-md-inline d-none">{{ $t('Editar') }}</span>
                        </b-link>
                        <b-link class="d-inline-block" @click=" deleteAddress(item.id) ">
                          <span class="pr-0_5">
                            <feather-icon icon="Trash2Icon" />
                          </span>
                          <span class="d-md-inline d-none">{{$t('Eliminar')}}</span>
                        </b-link>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <hr class="mt-0">
                    <b-link class="btn-danger btn-click" id="show-btn click"
                            @click=" clearModal(); type = 'shipping'; $bvModal.show('add-address') ">
                      + {{ $t('add_address') }}
                    </b-link>
                  </b-col>
                </b-row>
              </b-col>



              <!-- billing -->
              <b-col md="6" sm="12" class="py-1">
                <p class="font-weight-bold">
                  {{ $t('invoice_address') }}
                </p>
                <hr>
                <b-row v-for="(   item   ) in    client.billing_address   " :id=" item.id " :key=" item.id " ref="row">
                  <b-col sm="12">
                    <b-row>
                      <b-col lg="7" cols="9">
                        <b-form-group v-slot=" { billingoptions } ">
                          <b-form-radio v-model=" billing " :aria-describedby=" billingoptions " name="billing-radios"
                            :value=" item.id ">{{ item.name }}</b-form-radio>
                        </b-form-group>
                      </b-col>
                      <b-col lg="5" cols="3" class="px-10 text-right d-flex justify-content-end">
                        <b-link id="show-btn click"
                                class="pr-1"
                                @click=" updateAdd(item); type = 'billing'; $bvModal.show('add-address') ">
                          <span class="pr-0_5">
                            <feather-icon icon="Edit2Icon" />
                          </span>
                          <span class="d-md-inline d-none">{{ $t('Editar') }}</span>
                        </b-link>
                        <b-link class="d-inline-block" @click=" deleteAddress(item.id) ">
                          <span class="pr-0_5">
                            <feather-icon icon="Trash2Icon" />
                          </span>
                          <span class="d-md-inline d-none">{{ $t('Eliminar') }}</span>
                        </b-link>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <hr class="mt-0">
                    <b-link class="btn-danger btn-click" id="show-btn click"
                            @click=" clearModal(); type = 'billing'; $bvModal.show('add-address') ">
                      + {{ $t('add_address') }}
                    </b-link>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-card>
        </b-tab>
      </b-tabs>

      <!-- modal add-->
      <b-modal id="add-address" :hide-footer=" true " size="lg" no-close-on-backdrop>
        <validation-observer ref="addAddress">
          <b-card-title title-tag="h1" class="font-weight-bolder mb-1 text-center">
            {{ $t('new_address') }}
          </b-card-title>
          <div class="asignar-trabajos-modal">
            <b-row class="justify-content-center middle" align-v="center">
              <b-col class="text-center" lg="10" />
            </b-row>

            <b-form class="mt-2" @submit.prevent=" handleSubmitAddAddress " @keydown.enter=" handleSubmitAddAddress ">
              <b-container>
                <b-row class="justify-content-center middle" align-v="center">
                  <b-col cols="12" lg="10">

                    <b-row>
                      <b-col lg="6" md="6" sm="12">
                        <!-- nombre address client -->
                        <b-form-group :label="$t('Nombre')" label-for="register-name-client">
                          <validation-provider #default=" { errors } " :name="$t('Nombre')" rules="required">
                            <b-form-input id="register-name-client" v-model=" ClientName "
                              :state=" errors.length > 0 ? false : null " name="register-name-client"
                              :placeholder="$t('Nombre')" />
                            <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                        <validation-provider #default="{ errors }" :name="'Teléfono'" rules="required">
                          <b-form-group :label="'Teléfono*'" label-for="client-phone">
                            <b-form-input
                              v-model="phone"
                              name="reference"
                              :placeholder="'Teléfono*'"
                            />
                          </b-form-group>
                          <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        <!-- dirección client -->
                        <b-form-group :label="$t('Direccion')" label-for="register-address-client">
                          <validation-provider #default=" { errors } " :name="$t('Direccion')" rules="required">
                            <b-form-input id="register-address-client" v-model=" ClientAddress "
                              :state=" errors.length > 0 ? false : null " name="register-address-client"
                              :placeholder="$t('Direccion')" />
                            <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                        <!-- cp client -->
                        <b-form-group :label="$t('CodigoPostal')" label-for="register-cp-client">
                          <validation-provider #default=" { errors } " :name="$t('CodigoPostal')" rules="required|length:5">
                            <b-form-input id="register-cp-client" v-model=" ClientCP "
                              :state=" errors.length > 0 ? false : null " name="register-cp-client"
                              :placeholder="$t('CodigoPostal')" />
                            <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col lg="6" md="6" sm="12">

                        <!-- town client -->
                        <b-form-group :label="$t('Municipio')" label-for="register-town-client">
                          <validation-provider #default=" { errors } " :name="$t('Municipio')" rules="required">
                            <b-form-input id="register-town-client" v-model=" ClientTown "
                              :state=" errors.length > 0 ? false : null " name="register-town-client"
                              :placeholder="$t('Municipio')" />
                            <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                        <!-- province client -->
                        <b-form-group :label="$t('Provincia')" label-for="register-province-client">
                        <validation-provider #default="{ errors }" :name="$t('Provincia')" rules="required">
                          <v-select
                            v-if="ClientCountry.code === 'ES'"
                            v-model="ClientProvince"
                            label="name"
                            :options="selectProvinces"
                            :filterable="true"
                            :searchable="true"
                            :placeholder="$t('Provincia')"
                            :disabled="!ClientCountry"
                          />
                          <b-form-input
                            v-else
                            v-model="ClientProvince"
                            :placeholder="$t('Provincia')"
                            :disabled="!ClientCountry"
                          />
                          <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                        <!-- pais client -->
                        <b-form-group :label="$t('Pais')" label-for="register-country-client">
                          <validation-provider #default=" { errors } " :name="$t('Pais')" rules="required">
                            <v-select
                              id="register-country-client"
                              @input="handlerClientCountry"
                              :value="ClientCountry"
                              :state="errors.length > 0 ? false : null"
                              :options="listCountries"
                              label="name"
                              name="register-country-client"
                              :placeholder="$t('Pais')"
                              :clearable="false"
                            />
                            <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row class="text-center mb-3">
                  <b-col>
                    <b-button type="submit" variant="primary" class="mt-1 mr-1 text-uppercase">
                      {{ $t('Guardar') }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-container>
            </b-form>
          </div>
        </validation-observer>
      </b-modal>
    </b-col>
  </b-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BRow, BCol, BCard, BLink, BButton, BFormRadio, BFormGroup, BForm, BFormInput, BContainer, BCardTitle, BBadge, BTable, BTabs, BTab, BPagination, BFormSelect, BCollapse
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { config } from '@/shared/app.config'

export default {
  components: {
    BButton,
    BPagination,
    BFormSelect,
    vSelect,
    BLink,
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BContainer,
    BFormRadio,
    BFormGroup,
    BForm,
    BFormInput,
    BBadge,
    BTable, BTabs, BTab, BCollapse
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      shipping: '',
      billing: '',
      phone: '',
      phoneInstallation: '',
      ClientCountry: '',
      ClientAddress: '',
      ClientCP: '',
      ClientTown:'',
      ClientProvince: '',
      ClientName:'',
      edit: false,

      currentPage: 1,
      pageLength: 10,
      dir: false,
      pages: ['10', '15', '25'],
      sortBy: '',
      sortDesc: '',

      visibleFilter: false,
      statusAssetVariants: config.statusAssetVariants,
      projectColumns: [
        {
          label: "Referencia",
          key: "ref",
          sortable: true,
        },
        {
          label: "Obra",
          key: "name",
        },
        {
          label: "Provincia",
          key: "province.name",
        },
        {
          label: "Nº Equipos",
          key: "total_assets",
        },
        {
          label: "Estado",
          key: "status",
        },
        {
          label: "Acciones",
          key: "actions",
          class: "column-actions",
        },
      ],
      status: '',
      searchTerm: '',
    }
  },
  watch: {
    status() {
      this.currentPage = 1;
      this.chargeData();
    },
    sortBy() {
      this.currentPage = 1
      this.chargeData()
    },
    sortDesc() {
      this.currentPage = 1
      this.chargeData()
    },
  },
  computed: {
    ...mapGetters({
      countries: 'countries/getCountries',
      client: 'clients/getClient',
      items: 'addresses/getItems',
      selectProvinces: 'provinces/getSelectProvinces',
      projects: "projects/getItems",
      totalAddresses: 'addresses/getTotalItems',
      totalProjects: "projects/getTotalItems",
      selectStatus: 'orders/getSelectStatus',
      currentUser: 'auth/getUser',
      currentLanguage: 'languages/getCurrentLanguage',
    }),
    statusVariant() {
      const statusColor = {
        1: 'light-success',
        0: 'light-danger',
        true: 'light-success',
        false: 'light-danger',
      }

      return status => statusColor[status]
    },
    statusVariantText() {
      const statusText = {
        1: this.$t('status.client.active'),
        0: this.$t('status.client.deactivated'),
        true: this.$t('status.client.active'),
        false: this.$t('status.client.deactivated'),
      }

      return status => statusText[status]
    },
    statusVariantIcon() {
      const statusIcon = {
        1: 'CheckCircleIcon',
        0: 'XCircleIcon',
        active: 'CheckCircleIcon',
        inactive: 'XCircleIcon'
      }

      return status => statusIcon[status]
    },
    listCountries () {
      return this.countries.map((countrie) => {
        countrie.name = countrie.title[this.currentLanguage]
        return countrie
      })
    }
  },
  methods: {
    ...mapActions({
      getListCountries: 'countries/getListCountries',
      getClient: 'clients/getClient',
      list: 'addresses/getListAddress',
      addAddress: 'addresses/createAndRecload',
      editAddress: 'addresses/edit',
      delete: 'addresses/delete',
      getSelectProvinces: 'provinces/selectProvinces',
      getSelectStatus: 'orders/getSelectStatus',
      getListProjects: "projects/getListProjects",
    }),
    deleteAddress(id) {
      this.$bvModal
        .msgBoxConfirm(this.$t('addresses_section.delete_address_confirm'), {
          bodyClass: 'bodyDelete',
          footerClass: 'footerDelete',
          okVariant: 'danger',
          okTitle: this.$t('Eliminar'),
          cancelTitle: this.$t('Cancelar'),
          cancelVariant: 'outline-danger',
          centered: true,
        })
        .then(value => {
          if (value === true) {
            this.delete(id)
          }
        })
    },
    updateAdd(item) {
      let country = {}
      if (item.country_object) {
       country = {...item.country_object, name: item.country_object.title[this.currentLanguage]}
      }
      this.ClientCountry = country
      this.phone = item.phone
      this.phoneInstallation = item.phone_installation
      this.ClientAddress = item.address
      this.ClientCP = item.postalCode
      this.ClientTown = item.town
      this.ClientProvince = item.province_object || item.province_name,
      this.ClientName = item.name
      this.edit = item.id
    },
    clearModal() {
      this.ClientCountry = ''
      this.ClientAddress = ''
      this.ClientCP = ''
      this.ClientTown = ''
      this.ClientProvince = ''
      this.ClientName = ''
      this.phone = ''
      this.edit = false
    },
    async handleSubmitAddAddress() {
      this.$refs.addAddress.validate().then(success => {
        if (success) {
          const formData = this.createFormData()
          if (formData) {
            if (this.edit === false) {
              this.addAddress({ address: formData })
            } else {
              this.editAddress({ id: this.edit, address: formData })
            }
          }
        } else {
          this.$refs.errorField.scrollIntoView({
            behavior: 'smooth',
          block: 'end',
          });
        }
      })
    },
    createFormData() {
      const data = new FormData()
      data.append('name', this.ClientName)
      data.append('address', this.ClientAddress)
      data.append('town', this.ClientTown)
      data.append('province', this.ClientCountry.code === 'ES' ? this.ClientProvince.id : this.ClientProvince)
      data.append('country_id', this.ClientCountry.id)
      data.append('postalCode', this.ClientCP)
      data.append('type', this.type)
      data.append('phone', this.phone)
      data.append('phone_installation', this.phoneInstallation)
      data.append('client_id', this.client.id)

      return data
    },
    handleSearch() {
      this.currentPage = 1;
      this.chargeData();
    },
    handleChangePage(page) {
      this.currentPage = page;
      this.chargeData();
    },
    handlePageChange() {
      this.currentPage = 1;
      this.chargeData();
    },
    onSortChange(params) {
      this.currentPage = 1;
      this.chargeData();
    },
    async chargeData() {
      let orderBy = "";
      let status = this.status ? this.status.id : "";

      if (this.sortBy) {
        const type = this.sortDesc ? "desc" : "asc";
        orderBy = {
          column: this.sortBy,
          type,
        };
      }

      await this.getClient(this.$route.params.id)
      // console.log('estado', status)

      await this.getListProjects({
        client: this.$route.params.id,
        page: this.currentPage,
        per_page: this.pageLength,
        search: this.searchTerm,
        orderBy,
        // status: status,

      });

      await this.getSelectProvinces({
        page: 1, per_page: 9999, search: '', orderBy: ''
      })


      this.getSelectStatus({
        type: "project"
      })
    },
    handlerClientCountry(value) {
      this.ClientCountry = value
      this.ClientProvince = ''
    }
  },
  async created() {

    if (this.currentUser.roles[0].name !== 'super_admin') {
      var permit = false;
      this.currentUser.clients.forEach((element, index) => {
        console.log('this.$route.params.id === element.id', element.id, this.$route.params.id)
        if (this.$route.params.id == element.id) {
          //puede ver el cliente
          permit = true;
        }
      })
      console.log('permit', permit)
      // if (!permit) this.$router.push('/error-404')
    }

    await this.chargeData()
    await this.getClient(this.$route.params.id)
    await this.getListCountries({
      page: 1, per_page: 9999, search: '', orderBy: ''
    })
    await this.getSelectProvinces({
      page: 1, per_page: 9999, search: '', orderBy: ''
    })

  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
